.breadcrumb .clickable:hover {
  cursor: pointer;
  color: #40a9ff;
}

.breadcrumb .label {
  margin-left: 4px;
}

.breadcrumb .separator {
  margin: 0px 8px;
}