@import '~antd/dist/antd.less';

@primary-color: #039EFF;
// @secondary-color: #B2A8FB;
@processing-color: @purple-6;

@menu-dark-bg: transparent;
@menu-dark-submenu-bg: transparent;

@font-family: 'Nunito';

// Drawer
// @layout-header-background: linear-gradient(to bottom right, #0072BB, #244A74);

.ant-layout-sider {
  background: #0072BB
  // background: linear-gradient(to bottom, #003A5E, #002136);
}

.ant-layout-sider-trigger {
  background: #0064A2;
  // background: #001928;
}

// // Background
@layout-body-background: #EFF2F8;

// // Components
// @component-background: #FFF;

// .ant-layout-content {
//   background: #F8F7FF;
//   border-radius: 12px 0 0 0;
//   padding: 0px 16px;
//   box-shadow: inset 0 4px 8px -4px #f0f1f2;
// }

// .ant-card, .ant-card-bordered {
//   border-radius: 12px;
// }

// .ant-btn {
//   border-radius: 8px; 
// }

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.85)
}

.ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
  color: rgba(255, 255, 255, 0.85)
}